// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
//@import '~bootstrap/scss/bootstrap';

@import "css/datatables.bundle.css";
@import "mixins";
@import "../plugins/custom/fullcalendar/fullcalendar";
@import "~@fancyapps/fancybox/dist/jquery.fancybox.min.css";
@import '~bootstrap-select/sass/bootstrap-select.scss';

@import "custom/wizard";
@import "custom/login";
//@import "~bootstrap-select";
